<template>
  <Observer name="fade-up" class="text-center">
    <strong class="headline -upper" v-if="item && item.headline" v-html="item.headline" data-aos="fade-in"></strong>
    <h2 class="title" v-if="item && item.title" v-html="item.title" data-aos="fade-in"></h2>
    <div v-if="item && item.text" v-html="item.text" class="wysiwyg" data-aos="fade-in"></div>
  </Observer>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
export default {
  props: ['item'],
  name: 'headline_title_text',
  mixins: [ReadyHook]
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  .-icons .title {
    display: inline;
    font-size: em(24);
    font-weight: normal;
    color: var(--zenith-white) !important;
    background: var(--zenith-theme-primary) !important;
    box-shadow: -2px 0px 0px 6px var(--zenith-theme-primary);
  }

  .headline {
    display: block;
    font-size: em(16);
    line-height: em(24, 16);
    letter-spacing: em(2, 16);
    font-weight: 500;
    margin-bottom: 1rem;
    color: var(--zenith-theme-primary);

    @include bp(max md) {
      font-size: em(12);
      line-height: em(18, 12);
      letter-spacing: em(2, 12);
    }

    & + .wysiwyg {
      margin-top: 2rem;
    }
  }

  .title {
    font-size: em(40);
    line-height: em(44, 40);
    font-weight: 500;
    margin-bottom: 3rem;
    color: var(--zenith-theme-primary);

    @include bp(max md) {
      font-size: em(30);
      line-height: em(40, 30);
      margin-bottom: 2rem;
    }
  }

  .wysiwyg {
    font-size: em(20);
    line-height: em(36, 20);
    font-weight: 300;

    @include bp(max md) {
      font-size: em(16);
      line-height: em(26, 16);
    }

    /deep/ ul, /deep/ ol {
      padding: 0;
      margin-bottom: 2rem;
      font-size: em(18, 20);
      line-height: em(25, 18);
      letter-spacing: em(0.2, 18);
      list-style: none;

      li:before {
        content: '-';
        margin-right: 1.4rem;
      }

      @include bp(max md) {
        font-size: em(16);
        font-weight: normal;
        line-height: em(26, 16);
        letter-spacing: em(.2, 16);
      }
    }

    /deep/ a {
      color: var(--zenith-theme-primary);
      text-decoration: underline;
    }

    /deep/ p {
      color: var(--zenith-theme-primary);
      font-size: em(20, 20);
      line-height: em(36, 20);
      font-weight: 300;
      margin-bottom: 2rem;

      @include bp(max md) {
        font-size: em(16);
        font-weight: normal;
        line-height: em(26, 16);
        letter-spacing: em(.2, 16);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
